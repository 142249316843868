import React, { useRef } from 'react'
import styled from 'styled-components';
import logoImage from './logo.png';
import '../fonts/fonts.css';
import nimaigarg from './NimaiGarg.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin, faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons';

const PageContainer = styled.div`
  background-color: #FFF;
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 0 20px; /* Add padding for small screens */
  position: relative;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-radius: 5px;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const Logo = styled.img`
  height: 60px;
  width: 240px;
  margin-right: 10px;

  @media (max-width: 768px) {
    height: 30px;
    width: 30px;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.7rem;
  font-family: 'Helvetica', sans-serif;
  font-weight: 500;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const CenterTextSection = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.7rem;
  font-family: 'Helvetica', sans-serif;
  flex-direction: column; /* Stack buttons vertically */
  font-weight: 500;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;


const CenterText = styled.h1`
  font-size: 4.5rem;
  font-family: 'SFPro-Medium', sans-serif;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: 50px; /* Reduced margin-top */

  background: black;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media (max-width: 768px) {
    font-size: 2.5rem;
    margin-top: 30px; /* Reduced margin-top for smaller screens */
  }
`;

const LocationText = styled.h3`
  font-size: 1.75rem;
  font-family: 'Geist-Light', sans-serif;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  display: flex;
  color: gray;
  margin-top: -25px; /* Added negative margin to move it up */

  @media (max-width: 768px) {
    font-size: 2.5rem;
    margin-top: -10px; /* Adjusted for smaller screens */
  }
`;

// const SubCenterText = styled.h3`
//   font-size: 1.5rem;
//   font-family: 'SFPro-LightItalic', sans-serif;
//   font-weight: 400;
//   background: black;
//   justify-content: center;
//   align-items: center;
//   display: flex;
//   -webkit-background-clip: text; /* Clip the gradient to the text */
//   -webkit-text-fill-color: transparent; /* Make the text color transparent */
//   margin-bottom: 300px;
//   whitespace: no-wrap;

//   @media (max-width: 768px) {
//     font-size: 1rem;
//     margin-bottom: 30px;
//   }
// `;

const CenterTextButtonDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  flex-direction: row; /* Stack buttons vertically */
  margin-top: 350px;
  bottom: 20px; /* Place the buttons at the bottom */
  left: 20px; /* Stick to the left side */

  @media (max-width: 768px) {
    gap: 10px;
  }
`;

const LeftButton = styled.button`
  color: #fff; /* Text color: white */
  background-color: #000; /* Background color: black */
  border: none; /* No border */
  border-radius: 6px; /* Border radius */
  padding: 12px 32px; /* Adjust padding */
  font-size: 16px; /* Increase font size */
  cursor: pointer;
  font-family: 'Inter', sans-serif;
  width: auto; /* Dynamic width */

  &:hover, &:focus {
    background-color: #333; /* Darker shade of black on hover */
    border-color: #000; /* Keeps border color consistent */
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2); /* Subtle shadow on hover */
  }
`;

const RightButton = styled.button`
  color: #24292f; /* Text color */
  background-color: #ffffff; /* Background color */
  border: 1px solid rgba(27, 31, 35, 0.15); /* Border style */
  border-radius: 6px; /* Border radius */
  padding: 12px 32px; /* Adjusted padding to match the height and width */
  font-size: 16px; /* Increased font size */
  font-family: 'Inter', sans-serif;
  cursor: pointer;
  box-shadow: 0 1px 0 rgba(27, 31, 35, 0.04), inset 0 1px 0 rgba(255, 255, 255, 0.25); /* Subtle shadow */
  transition: background-color 0.2s ease, box-shadow 0.2s ease; /* Smooth transitions */
  
  white-space: nowrap; /* Prevent text from wrapping to the next line */

  &:hover, &:focus {
    background-color: #f6f8fa; /* Lighter background on hover */
    border-color: rgba(27, 31, 35, 0.15);
    box-shadow: 0 1px 0 rgba(27, 31, 35, 0.1); /* More pronounced shadow */
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;


const Box = styled.div`
  background-color: #f5f5f5;
  padding: 1rem 2rem;
  border-radius: 15px;
  box-shadow: 0 5px 7px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  max-height: 400px;
  margin: 0 auto; /* Center the box horizontally */

  @media (max-width: 768px) {
    max-width: 90%;
    padding: 1rem;
    margin: 0 1rem; /* Add margin for better spacing on mobile */
  }

  @media (max-width: 480px) {
    max-width: calc(100% - 2rem); /* Ensure space on both sides */
    padding: 0.8rem;
    margin: 0 1rem; /* Add margin for better spacing on smaller screens */
  }
`;

const BoxParagraph = styled.p`
  font-family: 'SFPro-Regular', sans-serif;
  font-size: 1.2rem;
  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const Circle = styled.button`
  background-color: #eeeeee;
  border-radius: 50%; /* Make it a circle */
  box-shadow: 0 5px 7px rgba(0, 0, 0, 0.1);
  width: 50px; /* Equal width and height */
  height: 50px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto; /* Center the box horizontally */
  padding: 0; /* Remove padding for equal distance around the circle */
  margin-right: 50px;
  margin-top: 330px;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 80px; /* Adjust size for smaller screens */
    height: 80px;
  }

  @media (max-width: 480px) {
    width: 60px; /* Further adjust for smaller screens */
    height: 60px;
  }
`;

// Styled Container
const ImageWrapper = styled.div`
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center;     /* Center vertically *
  height: auto;            /* Change height to auto */
  margin-top: -90px;
  position: absolute;

  left: 350px;
  top: 400px;
  width: 45%; /* You can adjust the width as needed */
  display: flex;

  justify-content: center;
  align-items: flex-end; /* Align image bottom */
  z-index: 0; /* Ensure it's behind buttons */
`;

// Styled Image (Optional for size adjustment)
const Image = styled.img`
  max-width: 82%; /* Responsive image */
  max-height: 82%; /* Ensure it fits within the container */
  width: auto;
  height: auto;
  margin-right: -100px;
  margin-bottom: 100px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const ButtonImageContainer = styled.div`
  display: flex;
  align-items: center; /* Center items vertically */
  justify-content: space-between; /* Spread the image and arrow button horizontally */
  bottom: 20px; /* Place at the bottom */
  width: 100%; /* Take full width for better control */
  z-jndex: 1;
`;

const ContactSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start; /* Aligns content to the top */
  padding: 20px;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box; /* To ensure padding is included in the width */
`;

const SectionHeader = styled.h1`
  font-size: 4rem;
  font-family: 'SFPro-Medium', sans-serif;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: 80px;
  
  background: black;
  -webkit-background-clip: text; /* Clip the gradient to the text */
  -webkit-text-fill-color: transparent; /* Make the text color transparent */

  @media (max-width: 768px) {
    font-size: 2.5rem;
    margin-top: 50px;
  }
`;

const ContactWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start; /* Align items to the top */
  padding: 20px;
  margin-top: 50px;
  width: 100%;
  flex-direction: row; /* Make it a row on larger screens */

  @media (max-width: 768px) {
    flex-direction: column; /* Stack items on smaller screens */
    align-items: center; /* Center align items for smaller screens */
  }
`;

// const ContactLeft = styled.div`
//   flex: 1; /* Take up equal space with the form */
//   padding-right: 40px; /* Add some space between the text and form */
//   @media (max-width: 768px) {
//     padding-right: 0;
//     margin-bottom: 20px; /* Add margin to separate the two sections on mobile */
//     text-align: center; /* Center text on smaller screens */
//   }
// `;

// const ContactRight = styled.div`
//   flex: 1; /* Take up equal space with the text */
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   width: 100%;
//   max-width: 600px;

//   @media (max-width: 768px) {
//     width: 90%;
//   }
// `;

const LeftSide = styled.div`
  flex: 1; /* Takes up 50% of the container */
  padding-right: 20px; /* Add some space between the two sides *
`;

const RightSide = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: -100px; /* Move the form 20px to the left */
  
  @media (max-width: 768px) {
    margin-left: 0; /* Remove the left margin for smaller screens */
  }
`;


const SmallHeader = styled.h3`
  font-size: 1.3rem;
  font-family: 'Geist-Medium', sans-serif;
  margin-top: 80px;
  background: black;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-left: 10px; /* Move it to the right by 20px */

  @media (max-width: 768px) {
    font-size: 1rem;
    margin-top: 50px;
  }
`;

const AboutSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-radius: 5px;
`;

const ContactParagraph = styled.p`
  font-family: 'Geist-Regular', sans-serif;
  font-size: 1rem;
  color: gray;
  margin-top: 10px;
  margin-left: 10px; /* Move it to the right by 20px */

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 600px; /* Set a max-width for better layout control */
  box-sizing: border-box;
`;

const InputRow = styled.div`
  display: flex;
  justify-content: space-between; /* Space between Full Name and Email fields */
  width: 100%;
  gap: 20px; /* Add some gap between the two fields */
  box-sizing: border-box; /* Include padding in width calculations */
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
`;

const InputField = styled.input`
  padding: 15px;
  font-size: 1.2rem;
  background-color: #f0f0f0;
  border: none;
  border-radius: 30px;
  margin-bottom: 15px;
  width: 100%;
  box-sizing: border-box;
  font-family: 'SFPro-Regular', sans-serif;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px #007bff;
  }

  @media (max-width: 480px) {
    padding: 12px;
    font-size: 1rem;
  }
`;

const InputParagraphField = styled.textarea`
  padding: 15px;
  font-size: 1.2rem;
  background-color: #f0f0f0;
  border: none;
  border-radius: 15px;
  margin-bottom: 15px;
  width: 100%; /* Match the combined width of the two inputs */
  height: 250px;
  resize: none;
  box-sizing: border-box;
  font-family: 'SFPro-Regular', sans-serif;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px #007bff;
  }

  @media (max-width: 480px) {
    padding: 12px;
    font-size: 1rem;
    height: 200px;
  }
`;

const SubmitButton = styled.button`
  padding: 15px 25px;
  font-size: 1.2rem;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  font-family: 'SFPro-Regular', sans-serif;
  width: auto;
  max-width: 80%;
  box-sizing: border-box;
  align-self: center;

  &:hover {
    background-color: #0056b3;
  }

  @media (max-width: 480px) {
    padding: 12px 20px;
    font-size: 1rem;
    max-width: 90%;
  }
`;

const SocialMediaBox = styled.div`
  position: fixed;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f5f5f5;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  transition: transform 0.3s ease, background-color 0.3s ease;

  &:hover {
    transform: translateY(-50%) scale(1.2); /* Maintain vertical position while scaling */
    background-color: #e0e0e0;
  }

  @media (max-width: 768px) {
    right: 10px;
    padding: 5px;
  }
`;

const SocialIcon = styled.a`
  margin: 10px 0;
  color: #000;
  font-size: 24px;
  transition: transform 0.3s ease, color 0.3s ease;

  &:hover {
    color: #007bff;
    transform: scale(1.2); /* Increase the scale of individual icons */
  }

  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

const Home = () => {

  // Create a reference for the AboutSection
  const aboutSectionRef = useRef(null);
  const contactSectionRef = useRef(null);
  const myWorkSectionRef = useRef(null);

  // Function to scroll to the AboutSection when the Circle button is clicked
  const scrollToAbout = () => {
    if (aboutSectionRef.current) {
      aboutSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const scrollToContact = () => {
    if(contactSectionRef.current) {
      contactSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }

  const scrollToMyWork = () => {
    if(myWorkSectionRef.current) {
      myWorkSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }

  return (
    <PageContainer>
      <HeaderContainer>
        <Header>
          <Logo src={logoImage} alt="GourmetChef Logo" />
        </Header>
      </HeaderContainer>

      <CenterTextSection>
        <CenterText>Hi, I'm Nimai Garg</CenterText>
        <LocationText>📍 Cupertino, California</LocationText>
      </CenterTextSection>

      <ButtonImageContainer>
        <CenterTextButtonDiv>
          <LeftButton onClick={scrollToContact}>Contact</LeftButton>
          <RightButton onClick={scrollToMyWork}>My work</RightButton>
        </CenterTextButtonDiv>
        
        <ImageWrapper>
          <Image src={nimaigarg} alt="Example" /> {/* Display the image */}
        </ImageWrapper>

        {/* <SubCenterText>A self-motivated 16 year-old in high school</SubCenterText> */}

        <Circle onClick={scrollToAbout}>
          <FontAwesomeIcon 
            icon={faArrowDown} 
            style={{ color: 'gray', fontSize: '1.2rem' }} // Change color and size
          />
        </Circle>
      </ButtonImageContainer>

{/* About Section with ref */}
<AboutSection ref={aboutSectionRef}>
        <SectionHeader>About</SectionHeader>
      </AboutSection>


      <Box>
        <BoxParagraph>I'm a 16 year old self-motivated junior in high school working in programming and business.</BoxParagraph>
      </Box>

      <ContactSection ref={myWorkSectionRef}>
        <SectionHeader>My Projects</SectionHeader>
      </ContactSection>

{/* Contact Section */}
<ContactSection ref={contactSectionRef}>
  <SectionHeader>Contact</SectionHeader>
</ContactSection>

{/* Contact Row Wrapper */}
<ContactWrapper>
<LeftSide>
    <SmallHeader>Let's connect</SmallHeader>
    <ContactParagraph>
  My inbox is always open. Whether you have a question or want to chat, <br /> I'll try my best to get back to you as soon as possible!
</ContactParagraph>
  </LeftSide>

  {/* Right Side: FormContainer */}
  <RightSide>
    <FormContainer>
      <Form>
        <InputRow>
          <InputField placeholder="Full Name" />
          <InputField placeholder="Email Address" />
        </InputRow>
        <InputParagraphField placeholder="Type your message...." />
        <SubmitButton>Submit</SubmitButton>
      </Form>
    </FormContainer>
  </RightSide>
</ContactWrapper>

      {/* Social Media Box */}
      <SocialMediaBox>
        <SocialIcon href="https://linkedin.com/in/nimaigarg" target="_blank">
          <FontAwesomeIcon icon={faLinkedin} />
        </SocialIcon>
        <SocialIcon href="https://instagram.com/nimai_garg" target="_blank">
          <FontAwesomeIcon icon={faInstagram} />
        </SocialIcon>
        <SocialIcon href="https://www.tiktok.com/@ball_prodz" target="_blank">
          <FontAwesomeIcon icon={faTiktok} />
        </SocialIcon>
      </SocialMediaBox>
    </PageContainer>
  );
};

export default Home;